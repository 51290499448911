// Firebase App is always required and must be first
//import firebase from "firebase/app";
import * as firebase from 'firebase/app';
import "firebase/auth";
//import "firebase/firestore";
import 'firebase/firestore/memory';
import "firebase/storage";
import "firebase/performance";
//import "firebase/functions"

const config = {
	"apiKey": process.env.REACT_APP_FIREBASE_APIKEY,
	"authDomain": process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
	"databaseURL": process.env.REACT_APP_FIREBASE_DATABASEURL,
	"projectId": process.env.REACT_APP_FIREBASE_PROJECTID,
	"storageBucket": process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
	"messagingSenderId": process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
	"appId": process.env.REACT_APP_FIREBASE_APPID
};

//let app = null;
if (!firebase.apps.length) {
	//app = firebase.initializeApp(config);
	firebase.initializeApp(config);
}

/* local emulation
firebase.functions().useFunctionsEmulator('http://localhost:5001');
*/
//export const functions = app ? app.functions('europe-west1') : firebase.functions();

export const auth = firebase.auth();

export const fs = firebase.firestore();

export const perf = firebase.performance();

export let storage = null; // to be s// et by token info

export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp; // call as function

export const setStorageBucket = bucketName => {
	if (bucketName) storage = firebase.app().storage(bucketName).ref();
};